import { Tabs, TabList, TabPanels, Tab, TabPanel, Button } from "@chakra-ui/react"
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import styles from './Photos.module.css'

export default function Photos({ isOpen, closeModal }) {
  return <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className={styles.backdrop}
          onClose={closeModal}
        >
          <div className={`${styles.modal} flex flex-col items-center`}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="max-w-5xl w-full md:p-6 sm:p-2 my-8 overflow-hidden text-left border-2 align-middle border-qua transition-all transform bg-cin shadow-xl rounded-2xl">
                <Tabs variant="soft-rounded" colorScheme="red">
                  <div className='flex md:flex-row sm:flex-col'>
                    <TabList className='md:flex-1 sm:flex sm:flex-row'>
                      <Tab className='border-2 border-qua sm:m-1 md:m-2 sm:flex-1'>Bonifica Amianto</Tab>
                      <Tab className='border-2 border-qua sm:m-1 md:m-2 sm:flex-1'>Nuove Coperture</Tab>
                      <Tab className='border-2 border-qua sm:m-1 md:m-2 sm:flex-1y'>Ponteggi</Tab>
                    </TabList>
                    <Button
                      colorScheme="red"
                      type="button"
                      className="inline-flex justify-center mt-2 p-2 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      onClick={closeModal}
                    >
                      X
                    </Button>
                  </div>
                  <TabPanels>
                    <TabPanel>
                      <div className="grid md:grid-cols-3 gap-2">
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/bonifica/29.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/bonifica/17.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/bonifica/19.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/bonifica/20.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/bonifica/22.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/bonifica/27.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/bonifica/33.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/bonifica/13.png.webp')" }}
                          alt="bonifica"
                        />      
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="grid md:grid-cols-3 gap-2">
                        <div
                          className="w-auto h-80 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/coperture/11.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-80 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/coperture/2.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-80 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/coperture/3.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/coperture/4.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/coperture/5.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/coperture/6.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/coperture/7.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/coperture/8.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/coperture/1.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/coperture/12.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/coperture/16.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/coperture/21.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/coperture/26.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/coperture/28.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/coperture/35.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/coperture/36.png.webp')" }}
                          alt="bonifica"
                        />
                      </div>
                    </TabPanel>
                    <TabPanel>
                    <div className="grid md:grid-cols-3 gap-2">
                        <div
                          className="w-auto h-80 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/ponteggi/9.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-80 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/ponteggi/39.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-80 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/ponteggi/40.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/ponteggi/23.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/ponteggi/24.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/ponteggi/30.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/ponteggi/31.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/ponteggi/32.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/ponteggi/34.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/ponteggi/37.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/ponteggi/14.png.webp')" }}
                          alt="bonifica"
                        />
                        <div
                          className="w-auto h-60 md:mr-4 bg-center bg-contain bg-no-repeat"
                          style={{ backgroundImage:"url('/images/ponteggi/15.png.webp')" }}
                          alt="bonifica"
                        />         
                      </div>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
                <div className="mt-4">
                  <Button
                    colorScheme="red"
                    type="button"
                    className="inline-flex justify-center px-4 py-2 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={closeModal}
                  >
                    CHIUDI
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
}
