import Bonifica from "./components/Bonifica";
import Contatti from "./components/Contatti";
import Copertura from "./components/Copertura";
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import Ponteggi from "./components/Ponteggi";
import styles from './App.module.css'

function App() {
  return <div 
    className={styles.background}>
      <div className="mx-4 z-10">
        <Nav />
        <div className="flex flex-col w-full items-center">
          <div>
            <div className="text-justify text-left max-w-5xl mt-8">Prezzo, qualità, competenza ed affidabilità al servizio del cliente. La nostra pubblicità migliore sono i nostri clienti che hanno sempre potuto apprezzare la competenza, l’affidabilità e la disponibilità dei nostri tecnici e delle nostre maestranze. Eseguiamo qualsiasi tipo di copertura e qualsiasi lavoro di bonifica riguardante l’amianto in matrice compatta, quali coperture, serbatoi, canne fumarie, condotte idriche, pluviali, pavimenti in vinilamianto ecc. oltre alla raccolta e al trasporto di m.c.a. già accatastato o abbandonato. Siamo a vs. disposizione per qualsiasi chiarimento tecnico e per formularvi un preventivo di spesa che sarà ovviamente gratuito. Confrontate i nostri prezzi e non esitate a chiamarci. Eseguiamo tutte le lavorazioni solo con nostre maestranze compreso il montaggio dei ponteggi ove occorrano, sollevando il cliente dal fastidio di cercare diverse imprese per le varie lavorazioni. Possiamo inoltre risolvere i vostri problemi in caso di infiltrazioni di acqua da terrazzi o vecchie impermeabilizzazioni anche senza rimuovere la pavimentazione esistente.</div>
          </div>
          <div id="bonifica"className="font-bold rounded-md text-center py-2 mt-10 mb-4 bg-gradient-to-r from-uno to-due text-white max-w-5xl w-full text-sm items-center">BONIFICA AMIANTO</div>
          <Bonifica />
          <div id="coperture" className="font-bold rounded-md text-center py-2 mt-10 mb-4 bg-gradient-to-r from-uno to-due text-white max-w-5xl w-full text-sm items-center">NUOVE COPERTURE</div>
          <Copertura />
          <div id="ponteggi" className="font-bold rounded-md text-center py-2 mt-10 mb-4 bg-gradient-to-r from-uno to-due text-white max-w-5xl w-full text-sm items-center">PONTEGGI</div>
          <Ponteggi />
          <div id="contatti" className="font-bold rounded-md text-center py-2 mt-10 mb-4 bg-gradient-to-r from-uno to-due text-white max-w-5xl w-full text-sm items-center">CONTATTI</div>
          <Contatti/>
          <Footer />
        </div>
      </div>
  </div>
}

export default App;
