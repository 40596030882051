function Ponteggi () {
  return (
    <div className="w-full max-w-5xl">
      <div
        className="shadow-2xl sm:mb-4 md:float-left md:w-5/12 md:h-72 md:mr-4 bg-center bg-cover bg-no-repeat sm:w-full sm:h-80"
        style={{ backgroundImage:"url('/images/ponteggi/9.png.webp')" }}
        alt="ponteggi"
      />
      <div className='text-justify'>{"La Tegos da sempre esegue, la progettazione (P.imus), il montaggio e smontaggio dei ponteggi a telai prefabbricati, ha il personale con le competenze e gli attestati di legge adeguati per la realizzazione di strutture protettive integrate con tubi e giunti, anche di elevata complessità. Tali competenze sollevano i nostri clienti, nella necessità di fare lavori in altezza, dal dover trovare una seconda ditta per il montaggio dei ponteggi in modo tale da avere tutti i lavori svolti da un’unica impresa, evitando, quindi i costi per la nomina del coordinatore della Sicurezza e tutti gli obblighi ad essa connessi. Tra i nostri clienti ci sono grandi aziende ed enti pubblici e privati, ma anche piccole aziende e privati. Siamo sempre in grado di soddisfare ogni esigenza, non solo progettando i ponteggi più idonei alle vostre necessità professionali, ma anche assicurando un servizio di montaggio e smontaggio dei ponteggi eseguito a regola d'arte. Potete contattarci in qualsiasi momento compilando il modulo nella sezione Contatti per avere maggiori informazioni sul nostro servizio o richiedere un preventivo gratuito e senza impegno. Organizzeremo in breve tempo un sopralluogo."}</div>
    </div>
  )
}

export default Ponteggi