function Copertura () {
  return (
    <div className="w-full max-w-5xl">
      <div
        className="shadow-2xl sm:mb-4 md:float-right md:w-5/12 md:h-72 md:ml-4 bg-center bg-cover bg-no-repeat sm:w-full sm:h-80"
        style={{ backgroundImage:"url('/images/coperture/28.png.webp')" }}
        alt="coperture"
      />
      <div className='text-justify'>{"La Tegos vanta una notevole esperienza delle sue maestranze e dei suoi tecnici nel settore riguardante le coperture e impermeabilizzazioni di qualunque tipo ed è in grado di risolvere qualunque problematica ad essa connessa. Non esitate a contattarci, siamo a vostra disposizione per studiare con voi la migliore soluzione tecnica, estetica ed economica e formularvi un preventivo di spesa completamente gratuito."}</div>
    </div>
  )
}

export default Copertura