const API_KEY = 'AIzaSyA0IjfloWzHiCK9zzfuP1xUVq9kU2Z1FtA'

const App = () => {
  return (
  <div className='App'>
    <iframe
      height='480'
      width='640'
      style={{ border: 0, width: '90%', margin: '2em auto' }}
      loading='lazy'
      allowFullScreen={true}
      src={`https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=Tegos+Srl,+Scafa+PE,+Italy`}
      title='map'
    ></iframe>
  </div>
  )
}

export default App
