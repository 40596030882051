import { Link } from "react-scroll"
import { Menu, Transition } from '@headlessui/react'
import Photos from "./Photos"
import { useState } from "react"

function Nav () {
  const [modalOpened, setModalOpened] = useState(false)

  const openModal = _ => setModalOpened(true)
  const closeModal = _ => setModalOpened(false)

  return <>
    <div className="flex flex-col items-center w-full">
      <div
        className="md:w-72 md:h-36 bg-center bg-contain bg-no-repeat sm:w-72 sm:h-36"
        style={{ backgroundImage:"url('/images/logo.png')" }}
        alt="logo"
      />
      <div className="flex flex-row sm:hidden h-8 bg-gradient-to-r from-uno to-due shadow-md rounded-md text-white text-sm max-w-5xl w-full justify-between items-center gap-4">
        <Link to="bonifica" className="font-bold cursor-pointer w-40 text-center">BONIFICA AMIANTO</Link>
        <Link to="coperture" className="font-bold cursor-pointer w-40 text-center">NUOVE COPERTURE</Link>
        <Link to="ponteggi" className="font-bold cursor-pointer w-40 text-center">PONTEGGI</Link>
        <Link to="contatti" className="font-bold cursor-pointer w-40 text-center">CONTATTI</Link>
        <div className="cursor-pointer w-40 text-center">  
          <button
            type="button"
            onClick={openModal}
            className="font-bold"
          >
            FOTO
          </button>
        </div>
      </div> 
      <Menu>
        <Menu.Button className="font-bold md:hidden h-8 bg-gradient-to-r from-uno to-due text-white text-sm max-w-5xl w-full justify-between items-center gap-4">MENU</Menu.Button>
        <Transition
          enter="transition duration-200 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Menu.Items className='flex rounded-b-md flex-col bg-gradient-to-r from-uno to-due p-4 px-10 text-white'>
            <Menu.Item>
              {({ active }) => (
                <Link to="bonifica" className="cursor-pointer w-40 text-center">BONIFICA AMIANTO</Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link to="coperture" className="cursor-pointer w-40 text-center">NUOVE COPERTURE</Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link to="ponteggi" className="cursor-pointer w-40 text-center">PONTEGGI</Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link to="contatti" className="cursor-pointer w-40 text-center">CONTATTI</Link>
              )}
            </Menu.Item>
            <Menu.Item>
              <div className="cursor-pointer w-40 text-center">           
                <button
                  type="button"
                  onClick={openModal}
                >
                  FOTO
                </button>
              </div>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
    <Photos isOpen={modalOpened} closeModal={closeModal} />
  </>
}

export default Nav
