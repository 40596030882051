function Bonifica () {
  return (
    <div className="w-full max-w-5xl">
      <div
        className="shadow-2xl md:float-left sm:mb-4 md:w-5/12 md:h-72 md:mr-4 bg-center bg-cover bg-no-repeat sm:w-full sm:h-80"
        style={{ backgroundImage:"url('/images/bonifica/17.png.webp')" }}
        alt="bonifica"
      />
      <div className='text-justify'>{"L'amianto o asbesto è stato diffusamente utilizzato in edilizia per le sue qualità fonoassorbenti e termoisolanti. Questo materiale è altamente pericoloso per la salute dell’uomo in quanto l’esposizione a fibre di amianto provoca l’asbestosi, il carcinoma polmonare, il mesotelioma pleurico. La legge prevede che tutti i proprietari di immobili in cui sono presenti materiali contenenti amianto (m.c.a.) siano obbligati a verificarne lo stato di conservazione e conseguentemente disporre le misure di adeguata gestione. In caso di mancato adempimento sono previste anche eventuali sanzioni. In seguito a tale verifica (che possiamo eseguire anche noi) il proprietario può decidere se programmare un piano di manutenzione e controllo oppure procedere alla bonifica dei manufatti contenenti amianto. I tecnici e le maestranze della ditta Tegos vantano un’esperienza pluridecennale nel campo delle bonifiche dei manufatti contenenti amianto avendo eseguito centinaia di lavori anche di notevole grandezza e complessità, anche per importanti committenze di rilevanza internazionale. I lavori eseguiti di bonifica amianto hanno interessato qualunque tipologia di edificio civile o industriale. Siamo in grado di risolvere qualunque problematica riguardante i materiali contenti amianto e siamo disponibili ad eseguire qualunque tipologia di bonifica e di qualunque dimensione su tutto il territorio nazionale con prezzi molto concorrenziali. I nostri preventivi sono sono ovviamente gratuiti."}</div>
      <div className='flex flex-col items-center'>
        <div className="text-center shadow-lg rounded-md py-1 hover:shadow-inner mt-3 mb-4 bg-gradient-to-r from-uno to-due text-white max-w-5xl sm:w-full md:w-3/6 text-sm">
          <a href='/static/BONIFICA-AMIANTO.pdf' id="info_amianto">Altre informazioni sull'amianto</a>
        </div>
        <div className='text-center'>Documenti iscrizione all’Albo Nazionale Gestori Ambientali</div>
        <div className="text-center shadow-lg rounded-md py-1 hover:shadow-inner mb-1 bg-gradient-to-r from-uno to-due text-white max-w-5xl sm:w-full md:w-3/6 text-sm ">
          <a href='/static/iscrizione-albo-cat-10A.pdf' id="iscrizione_albo">Iscrizione albo cat. 10A</a>
        </div>
        <div className="text-center shadow-lg rounded-md py-1 hover:shadow-inner my-1 bg-gradient-to-r from-uno to-due text-white max-w-5xl sm:w-full md:w-3/6 text-sm">
          <a href='/static/AQ05990_Provv_2017_948-BC368DM.pdf' id="AQ05990_Provv_2017_948">AQ05990_Provv_2017_948 BC368DM</a>
        </div>
        <div className="text-center shadow-lg rounded-md py-1 hover:shadow-inner my-1 bg-gradient-to-r from-uno to-due text-white max-w-5xl sm:w-full md:w-3/6 text-sm">
          <a href='/static/AQ05990_Provv_2016_9329-cat-5.pdf' id="AQ05990_Provv_2016_9329">AQ05990_Provv_2016_9329 cat 5</a>
        </div>
      </div>
    </div>
  )
}

export default Bonifica