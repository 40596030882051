import Map from "./Map"
import { FaFacebook, FaInstagram } from 'react-icons/fa'

function Contatti () {
  return (
    <div className="w-full max-w-5xl text-center center">
      <p>INDIRIZZO</p>
      <p>Via F. P. Michetti, 4 cap 65027, Scafa (PE)</p>
      <br/>
      <p>INFORMAZIONI DI CONTATTO</p>
      <a href={"mailto:tegos.it@gmail.com"}>Email: tegos.it@gmail.com</a>
      <br/>
      <a href={"tel:+390859154250"}>Telefono: 085 91 54 250</a>
      <br/>
      <a href={"tel:+393277933911"}>Cellulare: 327 79 33 911</a>
      <br/>
      <br/>
      <p>ORARI</p>
      <p>Lunedì - Sabato 9:00 - 19:00</p>
      <p>Domenica Chiuso</p>
      <br/>
      <div className='flex flex-row justify-center gap-4 text-2xl'>
        <a href={'https://www.facebook.com/tegos.scafa'}><FaFacebook/></a>
        <a href={'https://www.instagram.com/tegos.it/'}><FaInstagram/></a>
      </div>
      <Map />
    </div>
  )
}

export default Contatti